<template>
	<div style="">
		<ul class="time-list">
			<li v-for="(item, index) in list" :key="index">
				<div class="title">
					<img width="31" height="20" :src="item.icon" />
					<span class="title-name">{{ item.name }}</span>
				</div>
				

				<div class="datetime">
					<!-- <span class="time">{{ calcTime(item.offset) }}</span>
					<span class="date">{{ calcDate(item.offset) }}</span> -->
					<span>{{item.timeStr}}</span>
				</div>
				<div class="utc">
					<span>UTC</span>
					<span v-if="item.offset >= 0">+</span>
					<span>{{ item.offset }}</span>
				</div>
				
			</li>
		</ul>
	</div>
</template>

<script>
function checkTime(i) {
	if (i < 10) {
		i = '0' + i;
	}
	return i;
}

function calcDate(offset) {
	var d = new Date();
	var utc = d.getTime() + d.getTimezoneOffset() * 60000;
	var nd = new Date(utc + 3600000 * offset);
	var Y = nd.getFullYear();
	var M = nd.getMonth() + 1;
	var D = nd.getDate();

	return Y + '-' + M + '-' + D;
}

function calcTime(offset) {
	var d = new Date();
	var utc = d.getTime() + d.getTimezoneOffset() * 60000;
	var nd = new Date(utc + 3600000 * offset);
	var h = nd.getHours();
	var m = nd.getMinutes();
	var s = nd.getSeconds();
	h = checkTime(h);
	m = checkTime(m);
	s = checkTime(s);
	return h + ':' + m + ':' + s;
}

function calcDateAndTime(offset) {
	var d = new Date();
	var utc = d.getTime() + d.getTimezoneOffset() * 60000;
	var nd = new Date(utc + 3600000 * offset);
	var Y = nd.getFullYear();
	var M = nd.getMonth() + 1;
	var D = nd.getDate();
	
	var h = nd.getHours();
	var m = nd.getMinutes();
	var s = nd.getSeconds();
	h = checkTime(h);
	m = checkTime(m);
	s = checkTime(s);

	return Y + '-' + M + '-' + D + ' '+ h + ':' + m + ':' + s;
}

export default {
	// name: 'barcode',

	// props: ['code'],
	props: {
		// mobile:"",
		// code: {
		//   default: function() {
		//     return '';
		//   },
		//   type: String
		// }
	},
	data() {
		return {
			setTimer:null,
			list: [
				{
					name: 'Beijing',
					icon: '/static/image/China.png',
					offset: 8,
					timeStr:''
				},
				//美国标准时
				// {
				// 	name: 'PST',
				// 	icon: '/static/image/USA.png',
				// 	offset: -8,
				// 	timeStr:''
				// },

				// {
				// 	name: 'CST',
				// 	icon: '/static/image/USA.png',
				// 	offset: -6,
				// 	timeStr:''
				// },
				// {
				// 	name: 'EST',
				// 	icon: '/static/image/USA.png',
				// 	offset: -5,
				// 	timeStr:''
				// },
				//美国夏令时
				{
					name: 'PDT',
					icon: '/static/image/USA.png',
					offset: -7,
					timeStr:''
				},
				{
					name: 'CDT',
					icon: '/static/image/USA.png',
					offset: -5,
					timeStr:''
				},
				{
					name: 'EDT',
					icon: '/static/image/USA.png',
					offset: -4,
					timeStr:''
				},
				
				{
					name: 'London',
					icon: '/static/image/England.png',
					offset: 0,
					timeStr:''
				},
				{
					name: 'Berlin',
					icon: '/static/image/Germany.png',
					offset: 1,
					timeStr:''
				},
				{
					name: 'Tokyo',
					icon: '/static/image/Japan.png',
					offset: 9,
					timeStr:''
				},
				{
					name: 'Bombay',
					icon: '/static/image/India.png',
					offset: 5.5,
					timeStr:''
				},
				// {
				// 	name: '巴黎-法国(Paris-France)',
				// 	icon: '🇫🇷',
				// 	offset: 2,
				// 	timeStr:''
				// },
				// {
				// 	name: '悉尼-澳大利亚(Sydney-Australian)',
				// 	icon: '🇦🇺',
				// 	offset: 10,
				// 	timeStr:''
				// }
			]
		};
	},
	// watch: {
	// 	code: function(newVal, oldVal) {
	// 		console.log('watchKey code',newVal);
	// 		this.initData();
	// 	}
	// },
	created() {
		console.log('created');
	},
	mounted() {
		console.log('monted');
		this.autoAction();
	},
	 beforeDestroy(){
		 clearTimeout(this.setTimer);
	 },
	methods: {
		initData() {},
		calcTime(offset) {
			return calcTime(offset);
		},
		calcDate(offset) {
			return calcDate(offset);
		},
		calcDateAndTime(offset) {
			return calcDateAndTime(offset);
		},
		autoAction(){
			clearTimeout(this.setTimer);
			this.setTimer = setTimeout(()=> {
			  this.list.forEach(item=>{
					item.timeStr = this.calcDateAndTime(item.offset);
				})
				console.log("autoAction");
				this.autoAction();
			}, 1000);
		},
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.time-list {
	display: flex;
	align-items: center;
	justify-content: space-around;
	li {
		margin-top: 10px;
		margin-left: 20px;
		line-height: 180%;
		.title {
			font-size: 12px;
			img {
				border: 1px solid #999;
			}
			.title-name {
				padding: 5px;
			}
		}
		.utc {
			font-size: 12px;
		}
		.datetime {
			font-size: 14px;
			// .date {
			// 	padding-left: 0.5rem;
			// 	font-size: 0.7rem;
			// }
			// .time {
			// 	font-size: 1.2rem;
			// }
		}
	}
}
</style>
