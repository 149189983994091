<template>
	<div style="">
		<div class="clock-con" v-if="timeData.year">
			<div class="clock-date">
				<div>{{ timeData.year }}-{{ timeData.month }}-{{ timeData.day }}</div>
				<div>
					<span v-if="'0' == timeData.weeks">{{$t('i18nn_abdbf97e2d5bdb25')}}</span>
					<span v-else-if="'1' == timeData.weeks">{{$t('i18nn_c2acf27a87ce160a')}}</span>
					<span v-else-if="'2' == timeData.weeks">{{$t('i18nn_48ecd1e7be525c75')}}</span>
					<span v-else-if="'3' == timeData.weeks">{{$t('i18nn_62551a706d64f9c9')}}</span>
					<span v-else-if="'4' == timeData.weeks">{{$t('i18nn_7c08f11a440bfb1c')}}</span>
					<span v-else-if="'5' == timeData.weeks">{{$t('i18nn_38b82f33d30ed560')}}</span>
					<span v-else-if="'6' == timeData.weeks">{{$t('i18nn_54904ecee1cf2823')}}</span>
				</div>
			</div>
			<div class="clock-time">{{ timeData.hours }}:{{ timeData.minutes }}:{{ timeData.seconds }}</div>
		</div>
	</div>
</template>

<script>
function checkTime(i) {
	if (i < 10) {
		i = '0' + i;
	}
	return i;
}

// function calcDate(offset) {
// 	var d = new Date();
// 	var utc = d.getTime() + d.getTimezoneOffset() * 60000;
// 	var nd = new Date(utc + 3600000 * offset);
// 	var Y = nd.getFullYear();
// 	var M = nd.getMonth() + 1;
// 	var D = nd.getDate();

// 	return Y + '-' + M + '-' + D;
// }

// function calcTime(offset) {
// 	var d = new Date();
// 	var utc = d.getTime() + d.getTimezoneOffset() * 60000;
// 	var nd = new Date(utc + 3600000 * offset);
// 	var h = nd.getHours();
// 	var m = nd.getMinutes();
// 	var s = nd.getSeconds();
// 	h = checkTime(h);
// 	m = checkTime(m);
// 	s = checkTime(s);
// 	return h + ':' + m + ':' + s;
// }

function calcNowDateAndTime() {
	// var d = new Date();
	// var utc = d.getTime() + d.getTimezoneOffset() * 60000;
	var nd = new Date();
	var Y = nd.getFullYear();
	var M = nd.getMonth() + 1;
	var D = nd.getDate();

	var h = nd.getHours();
	var m = nd.getMinutes();
	var s = nd.getSeconds();
	h = checkTime(h);
	m = checkTime(m);
	s = checkTime(s);

	var w = nd.getDay();

	// console.log(nd.getUTCHours());
	// return Y + '-' + M + '-' + D + ' '+ h + ':' + m + ':' + s;

	return {
		year: Y,
		month: M,
		day: D,
		hours: h,
		minutes: m,
		seconds: s,
		weeks: w
	};
}

export default {
	// name: 'barcode',
	props: {},
	data() {
		return {
			setTimer: null,
			timeData: {
				year: '',
				month: '',
				day: '',
				hours: '',
				minutes: '',
				seconds: '',
				weeks: ''
			}
		};
	},
	// watch: {
	// 	code: function(newVal, oldVal) {
	// 		console.log('watchKey code',newVal);
	// 		this.initData();
	// 	}
	// },
	created() {
		console.log('created');
	},
	mounted() {
		console.log('monted');
		this.autoAction();
	},
	beforeDestroy() {
		clearTimeout(this.setTimer);
	},
	methods: {
		initData() {},
		// calcTime(offset) {
		// 	return calcTime(offset);
		// },
		// calcDate(offset) {
		// 	return calcDate(offset);
		// },
		calcNowDateAndTime() {
			return calcNowDateAndTime();
		},
		autoAction() {
			clearTimeout(this.setTimer);
			this.setTimer = setTimeout(() => {
				// this.list.forEach(item=>{
				this.timeData = this.calcNowDateAndTime();
				// })
				// console.log("autoAction");
				this.autoAction();
			}, 1000);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.clock-con {
	max-width: 220px;
	padding: 20px;
}
.clock-date {
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 18px;
	line-height: 120%;
}
.clock-time {
	font-size: 50px;
	line-height: 120%;
	font-weight: bold;
	text-align: center;
	font-family: Sans-serif;
}
</style>
