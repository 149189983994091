<template>
	<div>
		<el-header class="myheader">
			<LoginHeader></LoginHeader>
		</el-header>
		<div style="max-width: 1200px;margin: 0 auto;">
			<ClockTime></ClockTime>
		</div>
		<div class="loginAll">
			<!-- <div id="paypal-button-container"></div> -->
			<el-card shadow="always" v-loading="ruleForm.loading" element-loading-text="login...">
				<!-- <el-container> -->
				<!-- <el-main> -->
				<h2>{{$t('0fff44c827a4f3b6')}}</h2>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="loginForm">
					<el-form-item :label="$t('i18nn_a1805b0f0117f109')" prop="account">
						<el-input v-model="ruleForm.account" :placeholder="$t('i18nn_04152d1213c1a758')" @keyup.enter.native="submitForm('ruleForm')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('604a0b11e916f387')" prop="password">
						<el-input type="password" v-model="ruleForm.password" :placeholder="$t('i18nn_e93cb6cc8d5978ed')" @keyup.enter.native="submitForm('ruleForm')" show-password></el-input>
					</el-form-item>
					<!-- <el-form-item :label="$t('i18nn_a6ab8f96b90f8df1')" prop="userType">
					    <el-select v-model="ruleForm.userType" :placeholder="$t('i18nn_eae360b6837f15e9')">
					      <el-option :label="$t('i18nn_5cabbc13e4bee21a')" value="TALLYPERSON"></el-option>
					      <el-option :label="$t('i18nn_db9cb45c970a316e')" value="TALLYPERSON2"></el-option>
					    </el-select>
					  </el-form-item> -->
					<el-form-item>
						<el-button type="primary" style="width: 100%;" @click="submitForm('ruleForm')">{{$t('c7609e554782bec4')}}</el-button>
						<!-- <el-button @click="resetForm('ruleForm')">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button> -->
					</el-form-item>
					<!-- <div class="loginTipMsg">温馨提示：请使用账号密码登录。</div> -->
				</el-form>
				<!-- </el-main> -->
				<!-- </el-container> -->
				<div class="netLine">
					<a :href="SOURCE_URL.CN" :title="SOURCE_URL.CN" target="_blank">
						<span v-if="SOURCE_URL.CN_HostName==CurrentHostName">(<span>{{$t('i18nn_89304b37afa2f1b0')}}</span>)</span>
						<span><span>{{$t('i18nn_578842e17fea3b6a')}}</span>:<span>{{$t('i18nn_cb143acd6c929826')}}</span>(CN)</span>
					</a>
					<a :href="SOURCE_URL.US" :title="SOURCE_URL.US" target="_blank">
						<span v-if="SOURCE_URL.US_HostName==CurrentHostName">(<span>{{$t('i18nn_89304b37afa2f1b0')}}</span>)</span>
						<span><span>{{$t('i18nn_4fd9ca7a902c7edb')}}</span>:<span>{{$t('i18nn_09b2a7a32b3c8db1')}}</span>(US)</span>
					</a>
				</div>
			</el-card>
		</div>
		<div class="loginAll-pla" style="margin-top: 20%;height: 220px;"></div>
		
		
		<div style="width: 1000px; margin: 0 auto;">
			<WorldTime></WorldTime>
		</div>
		<div class="" style="text-align: center;margin-top:10px;color:rgb(102, 102, 102);">
		  &copy;&nbsp;2024&nbsp;HYTX&nbsp;All&nbsp;rights&nbsp;reserved
		</div>
	</div>
</template>
<script>
	import LoginHeader from '@/layout/Header/LoginHeader.vue';
	import ClockTime from '@/components/Common/ClockTime.vue';
	import WorldTime from '@/components/Common/WorldTime.vue';
	import { HYTX_SOURCE } from '@/config/platformConfig.js';
export default {
	components: { LoginHeader,WorldTime,ClockTime },
	data() {
		return {
			// activeLang: this.$i18n.locale,
			loadPackNum: '',
			ruleForm: {
				loading: false,
				account: '',
				// userType: '',
				password: ''
			},
			SOURCE_URL: {},
			CurrentHostName: window.location.hostname,
			rules: {
				account: [
					{ required: true, message: this.$t('i18nn_04152d1213c1a758'), trigger: 'blur' }
					// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				],

				password: [
					{ required: true, message: this.$t('i18nn_e93cb6cc8d5978ed'), trigger: 'blur' }
					// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				]
				// userType: [
				//   { required: true, message: this.$t('i18nn_eae360b6837f15e9'), trigger: 'change' }
				// ]
			}
		};
	},

	mounted() {
		console.log('mounted');
		// console.log("CurrentHostName",this.CurrentHostName);
		this.SOURCE_URL = HYTX_SOURCE;
		this.init();
    
		// this.$nextTick(()=>{
		// 	paypal.Buttons({
		// 	  style: {
		// 	    layout: 'vertical',
		// 	    color:  'blue',
		// 	    shape:  'rect',
		// 	    label:  'paypal'
		// 	  }
		// 	}).render('#paypal-button-container');
		// });
		
		
    // this.$http
    //   .put(this.$urlConfig.WhPriterOrderRate + '/' + '123123', {})
	},
	methods: {
		init() {
			this.$store.dispatch('USER_SIGNOUT');
			this.$store.dispatch('MENUS_SIGNOUT');
		},
		//切换语言
		// switchLang(lang) {
		// 	this.$i18n.locale = lang;
		// 	this.$store.dispatch('UPDATE_LANG', lang);
		// 	this.$message.success(this.$t('topBar.switchLangSuccess'));
		// },
		submitForm(formName) {
			// this.$router.replace({name:'FramePage1'});
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loginAction();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			this[formName] = {};
			this.$refs[formName].resetFields();
		},

		loginAction() {
			console.log(this);

			this.ruleForm.loading = true;

			this.$http
				.post(this.$urlConfig.HyLogin, {
					account: this.ruleForm.account,
					password: this.ruleForm.password
				})
				.then(response => {
					// console.log(this.$t('0fff44c827a4f3b6'), response);
					this.ruleForm.loading = false;
					if (response.data && '200' === response.data.code) {
						this.$message.success(this.$t('i18nn_e5368dfd7865a5f1')+','+this.$t('i18nn_b698f53c03aa9dc7')+'...');

						this.getPremissMenu();
					} else {
						// that.$message.error('登录失败！');
						this.$message.warning(response.data.msg ? response.data.msg : this.$t('i18nn_5fe6cc0473e0d313'));
					}
				})
				.catch(error => {
					console.log(error);
					this.ruleForm.loading = false;
					this.$message.error(this.$t('i18nn_14d8615847c3bb28')+'！');
				});
		},

		//获得用户权限菜单
		getPremissMenu() {
			console.log(this);
			// let that = this;
			this.ruleForm.loading = true;
			this.$http
				.get(this.$urlConfig.HyAdminMenuAllPage)
				.then(response => {
					// console.log(response);
					this.ruleForm.loading = false;
					if (response.data && '200' === response.data.code) {
						console.log(this.$t('i18nn_48fea1870aa235fd'));
						// this.$router.push({name:'Frame'});
						this.loginSuccess(response.data);
					} else {
						this.$message.warning(response.data.msg ? response.data.msg : this.$t('i18nn_725d750832726cc3'));
					}
				})
				.catch(error => {
					console.log(error);
					this.ruleForm.loading = false;
					this.$message.error(this.$t('i18nn_747b33bfa850b075')+'！');
				});
		},
		///获得用户权限菜单成功
		loginSuccess(data) {
			//清除缓存
			this.$store.dispatch('USER_SIGNOUT');
			this.$store.dispatch('MENUS_SIGNOUT');
			
			let whMenusArr = [];
			
			data.menus.map(item => {
				// return this.$t('i18nn_fb45aad612581e75') == item.menuName;
				if(item.request.indexOf("WarehouseCenter")>-1){
					whMenusArr.push(item)
				}
			});
			// let whMenusArr = [whMenus];

			if (!data.user || !whMenusArr) {
				this.$message.warning(this.$t('i18nn_85f24646cf45f5ee')+'，'+this.$t('i18nn_71bc43a0fa1cbad0'));
				return;
			} else {
				this.$message.success(this.$t('i18nn_b125fa6fe5308e6a'));
			}

			//重新设置
			this.$store.dispatch('UPDATE_USERINFO', data.user);
			this.$store.dispatch('UPDATE_MENUS', whMenusArr);

			// this.$store.commit('UPDATE_MENUS', data.menus);

			//     this.$store.dispatch('UPDATE_MENUS',data.menus).then(res => {
			//     // this.$router.push('/index');、
			//     console.log(' this.$store',this.$store);
			//     console.log('UPDATE_MENUS this.$store.getters.getUserInfo',this.$store.getters.getUserInfo);
			//     console.log('UPDATE_MENUS this.$store.getters.getMenusInfo',this.$store.getters.getMenusInfo);
			//     let _this = this;
			//     setTimeout(function() {
			//            console.log('setTimeout this.$store.getters.getMenusInfo',_this.$store.getters.getMenusInfo);
			//        }, 1000)
			// }).catch( err => {
			//     console.log(err);
			// })
			// this.$store.commit('UPDATE_MENUS', data.menus);
			// sessionStorage.getItem('menusInfo')
			// sessionStorage.getItem('menusInfoKey')

			console.log('sessionStorage.getItem(menusInfo)', sessionStorage.getItem('menusInfo'));

			console.log('sessionStorage.getItem(menusInfoKey)', sessionStorage.getItem('menusInfoKey'));

			let menusInfo = this.$store.getters.getMenusInfo;

			let menusInfoKey = this.$store.getters.getMenusInfoKey;

			console.log('Frame menus', menusInfo);
			console.log('menusInfoKey', menusInfoKey);

			// return;

			// this.$router.push({path:'/Frame/WhWorkOrderList'});
			if (menusInfo && menusInfoKey && menusInfo.length > 0 && menusInfoKey.length > 0) {
				this.$router.push({ name: menusInfoKey[0] });
				// this.$router.push({name:"Home"});
			} else {
				//第二次赋值
				this.$store.dispatch('UPDATE_USERINFO', data.user);
				this.$store.dispatch('UPDATE_MENUS', whMenusArr);
				let menusInfo = this.$store.getters.getMenusInfo;
				let menusInfoKey = this.$store.getters.getMenusInfoKey;

				console.log('第二次赋值 Frame menus', menusInfo);
				console.log('第二次赋值 menusInfoKey', menusInfoKey);

				if (menusInfo && menusInfoKey && menusInfo.length > 0 && menusInfoKey.length > 0) {
					this.$router.push({ name: menusInfoKey[0] });
					// this.$router.push({name:"Home"});
				} else {
					this.$message.warning(this.$t('i18nn_18eac2972dd87064')+'，'+this.$t('i18nn_d0382156496d94ef'));
					setTimeout(function() {
						window.location.reload();
					}, 1000);
				}

				// JSON.parse(sessionStorage.getItem('menusInfo'))
			}

			// return;

			//  if(menusInfo&& menusInfo.length>0){
			//  	// let menus = this.filterRouter(menusInfo);
			//  	let menus = menusInfo;
			//  	console.log("menus",menus);
			//    // let routerMenus = [];
			//    if(menus){
			//    	console.log(this.$router.options);
			//    	// this.$router.options.routes.push(menus);
			//   // this.$router.addRoutes(this.$router.options.routes);//调用addRoutes添加路由
			//    	this.$router.push({name:'Frame'});
			//    } else {
			//    	console.log("menus NULL");
			// }
			//  } else {
			//  	console.log("menusInfo NULL",menusInfo);
			//  }
		}
		// //格式化，并添加路由
		// filterRouter(menusParm){
		// 	console.log('menusParm',menusParm);
		// 	console.log('menusParm',menusParm.length);
		// 	let userMenus = [];
		//  for (let i = 0,ln = menusParm.length; i < ln; i++) {
		//    let oneObj = menusParm[i];
		//    //是否存在子路由
		//    if(oneObj.menuDtoList && oneObj.menuDtoList.length>0){
		//    	let children = [];
		//    	for (let j = 0,lnn = oneObj.menuDtoList.length; j < lnn; j++) {
		//       let oneObj2 = oneObj.menuDtoList[j];
		//       //判断路由合法性
		//       if(!oneObj2 ||oneObj2.request.indexOf("#")>-1||oneObj2.request.indexOf(".html")>-1){
		//       	continue;
		//       } else {
		//       	//路由路径及参数合法性
		//        let oneObj2Path = oneObj2.request.split('/');
		//        if(oneObj2Path.length<=0){
		//        	continue;
		//        }
		//        children.push({
		//          path: oneObj2Path[oneObj2Path.length-1],
		//          name: oneObj2Path[oneObj2Path.length-1],
		//          component: resolve => require([oneObj2.request], resolve),
		//        });
		//       }
		//     }
		//    }

		//    //判断路由合法性
		//    if(!oneObj || oneObj.request.indexOf("#")>-1||oneObj.request.indexOf(".html")>-1){
		//      	continue;
		//      } else {
		//      	//路由路径及参数合法性
		//      let oneObjPath = oneObj.request.split('/');
		//      if(oneObjPath.length<=0){
		//       	continue;
		//       }
		//     let oneRouter = {
		//       path: oneObjPath[oneObjPath.length-1],
		//       name: oneObjPath[oneObjPath.length-1],
		//       component: resolve => require([oneObj.request], resolve),
		//       // children:children?children:null
		//     };
		//     if(children&&children.length>0){
		//       oneRouter.children = children;
		//     }
		//     userMenus.push(oneRouter)
		//      }

		//  }
		//  let AddRouter = {
		//    path: '/Frame',
		//    name: 'Frame',
		//    component: resolve => require(['@/components/Frame'], resolve),
		//    children: userMenus
		//  };
		//  return AddRouter;
		// },
	}
};
</script>

<style scoped lang="less">
.netLine {
	display: flex;
	justify-content: space-around;
}	
</style>
