//HYTX
export const HYTX_SOURCE = {
	CN:"https://admin.warehouse.hytx.com/",
	CN_HostName:"admin.warehouse.hytx.com",
	US:"https://usadmin.warehouse.hytx.com/",
	US_HostName:"usadmin.warehouse.hytx.com",
}
//BSD
export const BSD_SOURCE = {
	CN:"http://123.56.233.109:85/",
	CN_HostName:"123.56.233.109",
	//新-https://bsdwms.hytx.com/ http://bsdwms.hytx.com:85/ 兼容旧
	US:"http://47.252.36.4:85/",
	US_HostName:"47.252.36.4",
}